import React, {useEffect} from 'react';

const AutoIncrement = (props) => {
    let {value, onChange, id, required, schema, formData} = props;

    const { type, minimum } = schema;
    
    useEffect(() => {
        if (formData === undefined && value === undefined && id) {
            onChange(minimum + (parseInt(id.split("_")[2]) + 1).toString());
        }
        // eslint-disable-next-line
    });

    const handleBlur = () => {
        onChange(minimum + (parseInt(id.split("_")[2]) + 1).toString());
    }
    console.log(typeof value);
    return <div id={id}>
        <input
            className="form-control"
            value={value ? value.toString().replace(minimum, '') : value}
            pattern="[1-9]\d*"
            disabled={true}
            step={"1"}
            type={type}
            required={required}
            onBlur={handleBlur}
            onChange={e => onChange(e.target.value)}
        />
    </div>
}

export default AutoIncrement;
