import React, {useContext, useEffect} from 'react'
import {useDefaultTranslation} from "../common/Translation";
import {Link, useParams, useRouteMatch} from "react-router-dom";
import {convertToNewPath, SureAppName} from "../base";
import {Screen} from "../shared/Screen";
import {AuthApiClient} from "@blocksure/blocksure-core/dist/src/services/api-clients";
import {AuthContext} from "../common/context";

const authApiClient = new AuthApiClient(SureAppName);

export const AmendEmailConfirmed = () => (
    <Screen>
        <AuthContext.Consumer>{() => (
            <AmendEmailConfirmedScreen />
        )}
        </AuthContext.Consumer>
    </Screen>
);


export const AmendEmailConfirmedScreen = () => {
    const {t} = useDefaultTranslation();
    const {path} = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    const {updateAuth} = useContext(AuthContext);
    
    useEffect( () => {
        async function clearTokens() {
            const whoAmI = await authApiClient.whoAmI()
            if(whoAmI.username !== 'anonymous' ) {
                sessionStorage.clear();
                await authApiClient.clearJwtToken();
                await updateAuth(true);
            }
        }
        clearTokens();
        // eslint-disable-next-line
    }, []);
    
    return (
        <div className="menu">
                <div className="offset-md-2 col-md-8">
                    <div className="row">
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <p>
                                {t("emailChangeConfirmed")}&nbsp;
                                <Link to={{pathname: `${newPath}/login`}}>{t("clickHereToLogIn").slice(0, -1)}</Link>&nbsp; 
                                {t("emailChangeClickHere")}</p>
                        </div>
                    </div>
                </div>
            </div>
    );
};