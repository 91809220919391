import React, {useContext, useState} from 'react';
import {AuthContext, CurrentStepContext, PolicyListContext, ProductContext} from '../common/context';
import Progress from '../quote/Progress';
import {Redirect, useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {FLOW_AMEND, FLOW_CLAIM} from './StepWidget';
import Loading from '../widgets/Loading';
import Error from "@blocksure/blocksure-core/dist/src/components/widgets/Error";

export const CurrentStepProvider = ({
    children,
    flow
}) => {
    const params = useParams();
    const history = useHistory();
    const match = useRouteMatch();
    const [quoteSeen, setQuoteSeen] = useState(false);
    const {hasPassword} = useContext(AuthContext);
    const policyData = useContext(PolicyListContext);
    const quotes = policyData?.quotes;
    const activeQuote = quotes?.some((quote) => quote.sequenceId === params.productOrSequenceId);

    let {
        submissionSchema = {},
        submissionUiSchema = {},
        claimSchema = {},
        claimUiSchema = {},
        error = {}
    } = useContext(ProductContext);
    if (flow === FLOW_CLAIM) {
        submissionSchema = claimSchema;
        submissionUiSchema = claimUiSchema;
    }

    const stepSchemas = submissionSchema.properties;
    const stepNames = submissionUiSchema['ui:order'] && [...submissionUiSchema['ui:order']];

    // This means we haven't yet loaded the schemas, so we return a spinner until we have.
    if(error) return <Error>{error}</Error>;
    if (!stepNames) return <Loading/>;

    const currentStep = params.step;

    // If we're doing an amend, we remove quote it from the flow.
    if (flow === FLOW_AMEND || activeQuote) {
        // We don't show the screen with various quote options.
        const quoteStepIndex = stepNames.indexOf('quote');
        if (quoteStepIndex > -1) stepNames.splice(quoteStepIndex, 1);
    }

    // Various navigation vars.
    const basePath = (id) => `${match.path.split('/:')[0]}/${id}`;

    // If we've landed on this page, but don't yet have a step or are pointing to an invalid one, point to the first.
    if (stepNames && (currentStep === 'new' || !currentStep || stepNames.indexOf(currentStep) === -1)) {
        if (currentStep) console.info(`Invalid step: ${currentStep}`, stepNames);
        return <Redirect
                to={`${basePath(match.params.sequenceId || match.params.productOrSequenceId)}/${stepNames.filter(it => flow === FLOW_AMEND || it !== 'amend')[0]}`}/>;
    }

    // Prepare values for provider.
    const stepSchema = prepareStepData(stepSchemas[currentStep], submissionSchema.definitions);
    const stepUiSchema = submissionUiSchema[currentStep];

    // We change state by navigating - this will be null if there is no next step.
    // Optionally pass the ID of the policy sequence - useful for when MTAing and it changes.
    const navigate = (name, action) => name && ((id = params.productOrSequenceId) => {
        // Allows the navigate func to be used as an event handler.
        if (typeof id !== 'string') id = params.sequenceId || params.productOrSequenceId;
        if (currentStep === 'quote' && action === 'next') setQuoteSeen(true);
        history.push(`${basePath(id)}/${name}`);
        return true;
    });

    // Various functions to navigate to other steps - we use an altered set of steps as the quote step should only be seen once.
    let navigationSteps = quoteSeen ? stepNames.filter(it => it !== 'quote') : [...stepNames];
    if (hasPassword && navigationSteps.indexOf('password') > -1) navigationSteps.splice(navigationSteps.indexOf('password'), 1);
    if (flow !== FLOW_AMEND && navigationSteps.indexOf('amend') > -1) navigationSteps.splice(navigationSteps.indexOf('amend'), 1);
    const navigationStepIndex = navigationSteps.indexOf(currentStep);
    const firstStep = navigate(navigationSteps[0], 'first');
    const previousStepName = navigationStepIndex === 0 ? null : navigationSteps[navigationStepIndex - 1];
    const previousStep = navigate(previousStepName, 'previous');
    const nextStepName = navigationStepIndex === navigationSteps.length ? null : navigationSteps[navigationStepIndex + 1];
    const nextStep = navigate(nextStepName, 'next');

    // If true then transitioning from this page should update the policyholder's data.
    const shouldRegister = stepUiSchema['fm:policyholderData'] || false;

    // If true then transitioning from this page should generate a quote record.
    const shouldQuote = nextStepName === 'payment';

    return (
            <CurrentStepContext.Provider value={{
                stepNames,
                firstStep,
                previousStep,
                previousStepName,
                currentStep,
                nextStep,
                nextStepName,
                shouldRegister,
                shouldQuote,
                stepSchema,
                stepUiSchema
            }}>
                <Progress steps={stepNames.map(name => ({
                    name,
                    title: stepSchemas[name].title || name
                }))} active={currentStep}/>
                <div className="clearfix">&nbsp;</div>
                {children}
            </CurrentStepContext.Provider>
    );
};

// Attached the defs to each step.
export function prepareStepData(inputSchema, definitions) {
    return {
        ...inputSchema,
        definitions: {...inputSchema.definitions, ...definitions}
    };
}
