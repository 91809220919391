import React, {useContext, useState} from 'react';
import {Link, useHistory, useParams, useRouteMatch} from 'react-router-dom';
import {Button, Error} from '@blocksure/blocksure-core/dist/src/components/widgets';
import {Screen} from '../shared/Screen';
import {AuthContext, LocalStorageContext, PendingContext} from '../common/context';
import {useDefaultTranslation} from '../common/Translation';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import {base, convertToNewPath, SureAppName} from "../base";
import {UserApiClient} from "@blocksure/blocksure-core/dist/src/services/api-clients";

const userApiClient = new UserApiClient(SureAppName);

export const AmendEmail = () => (
        <Screen>
            <AuthContext.Consumer>{({auth}) => (
                    <AmendEmailScreen auth={auth}/>
            )}</AuthContext.Consumer>
        </Screen>
);

const AmendEmailScreen = ({
    auth
}) => {
    const history = useHistory();
    const {t, i18n} = useDefaultTranslation();
    const {
        pending,
        pendingAction
    } = useContext(PendingContext);
    const {path} = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    const {localStorage} = useContext(LocalStorageContext)
    const [error, setError] = useState(false);
    const [updatedEmail, setEmail] = useState(sessionStorage.getItem('com.blocksure.updatedEmail'));

    const onChange = (updatedEmail) => {
        setError(null);
        sessionStorage.setItem('com.blocksure.updatedEmail', updatedEmail);
        setEmail(updatedEmail);
    };

    const saveChanges = async () => {
        pendingAction(async () => {
            try {
                await userApiClient.triggerEmailUpdate(
                    auth.username,
                    updatedEmail,
                    `${window.location.origin.toString()}${base}/email/reject`,
                    `${window.location.origin.toString()}${base}/email/confirmed`,
                    id,
                    localStorage.getItem('app-name'),
                    i18n.language
                    )
                history.push(`${newPath}/email/request`);
            } catch (e) {
                console.error(`Invalid email [${updatedEmail}]].`, e);
                setError(generateErrorMessage(e, t('invalidEmail')));
            }
        });
    };

    const resetChange = () => {
        sessionStorage.removeItem('com.blocksure.updatedEmail');
    };

    return (
            <div className="menu">
                <div className="offset-md-2 col-md-8">
                    {error && <Error>{error}</Error>}
                    <div className="row">
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <label data-menukey="Existing" htmlFor="existing">{t('existingEmail')}</label>
                        </div>
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <input data-menukey="Email" className="form-control" type="email" id="existing" name="existing" disabled
                                   value={auth.username}/>
                        </div>
                    </div>
                    <div className="row gap">
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <label data-menukey="Label" htmlFor="email">{t('enterEmail')}</label>
                        </div>
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <input data-menukey="Input" className="form-control" type="email" id="email" name="email"
                                   onChange={e => onChange(e.target.value)} value={updatedEmail ? updatedEmail : ''}/>
                        </div>
                    </div>
                    <hr data-menukey="separator"/>
                    <div className="row">
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <Button data-menukey="Save" className="btn btn-primary gap float-right" disabled={!updatedEmail} pending={pending}
                                    onClick={saveChanges}>{t('save')}</Button>
                            <Link data-menukey="Cancel" role="button" className="btn btn-primary gap float-right" to={`${newPath}/`} disabled={pending}
                                  onClick={resetChange}>{t('cancel')}</Link>
                        </div>
                    </div>
                </div>
            </div>
    );
};
