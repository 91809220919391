import React, {useContext, useState} from 'react';
import {Screen} from "../shared/Screen";
import {AuthContext, PendingContext} from "../common/context";
import {Link, useHistory, useParams, useRouteMatch} from "react-router-dom";
import {convertToNewPath, SureAppName} from "../base";
import {Button, Error} from "@blocksure/blocksure-core/dist/src/components/widgets";
import {useDefaultTranslation} from "../common/Translation";
import {AuthApiClient} from "@blocksure/blocksure-core/dist/src/services/api-clients";

const authApiClient = new AuthApiClient(SureAppName);

export const AmendLogin = () => (
    <Screen>
            <AuthContext.Consumer>{({
                auth,
                policyholder
            }) => (
                <AmendLoginScreen auth={auth} policyholder={policyholder}/>
            )}</AuthContext.Consumer>
        </Screen>
);

const AmendLoginScreen = ({auth}) => {
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    const {
        pending,
        pendingAction
    } = useContext(PendingContext);
    const {t} = useDefaultTranslation();
    const [error, setError] = useState(false);

    const [password, setPassword] = useState();
    const history = useHistory();
    
    const validUser = async () => {
        pendingAction(async () => {
            try {
                await authApiClient.getToken({
                    auth: {
                        username: auth.username,
                        password
                    }
                });
                history.push(`${newPath}/email/new`);
            } catch (e) {
                console.error(`Invalid password [${auth.username}]].`, e);
                setError(t('invalidPassword'));
            }
        });
    };
    
    function updatePassword(pass) {
        setPassword(pass)
    }
    
    return (
        <div className="menu">
                <div className="offset-md-2 col-md-8">
                    {error && <Error>{error}</Error>}
                    <div className="row">
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <label data-menukey="Password" htmlFor="password">{t('password', {defaultValue: 'Password'})}</label>
                        </div>
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <input 
                                data-menukey="Email" className="form-control" type="password" id="password" name="password"
                                placeholder="••••••••" onChange={e => updatePassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 20}}>
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <Button data-menukey="Continue" className="btn btn-primary gap float-right" pending={pending}
                                    onClick={validUser}>{t('continue')}</Button>
                            <Link 
                                data-menukey="Cancel" role="button" 
                                className="btn btn-primary gap float-right" 
                                to={`${newPath}/`} disabled={pending}>{t('cancel')}</Link>
                        </div>
                    </div>
                </div>
            </div>)
};