import React, {useContext, useEffect, useState} from 'react';
import {ClaimSchemaContext, CurrentStepContext, PolicyContext} from '../common/context';
import {get, set} from 'lodash';
import Loading from '../widgets/Loading';
import {useDefaultTranslation} from '../common/Translation';

const REFERENCE_KEY = 'fm:ref:';

export const ClaimSchemaProvider = ({children}) => {
    const {i18n} = useDefaultTranslation();
    const {policy} = useContext(PolicyContext);
    const {
        stepSchema,
        stepUiSchema,
        currentStep
    } = useContext(CurrentStepContext);
    const [{
        updatedStepSchema,
        updatedStepUiSchema
    }, setClaimSchemas] = useState({});

    useEffect(() => {
        const {
            updatedSchema,
            updatedUiSchema
        } = initialiseClaimSchemas({policy}, stepSchema, stepUiSchema, policy.currency, i18n.language);
        setClaimSchemas({
            updatedStepSchema: updatedSchema,
            updatedStepUiSchema: updatedUiSchema
        });
        // eslint-disable-next-line
    }, [currentStep, policy, stepSchema, stepUiSchema]);

    if (!updatedStepSchema || !updatedStepUiSchema) return <Loading/>;

    return (
            <ClaimSchemaContext.Provider value={{
                stepSchema: updatedStepSchema,
                stepUiSchema: updatedStepUiSchema
            }}>
                {children}
            </ClaimSchemaContext.Provider>
    );
};

/**
 * You'd be forgiven for saying that this is a slightly tenuous bit of functionality. But the following allows one schema to reference the values of
 * another. The case in point being a product that allows a set of items to be insured, which the user can then indicate were affected in the claim.
 * So to achieve this we have a structure that can point to and manipulate the policy submission:
 *
 * uiSchema: {
 *     // This indicates we want to reference something else.
 *     "fm:ref:schema.enum": {
 *         // The path(s) to take data from.
 *         "path": [
 *             "policy.submission.furtherDetails.specifiedPersonalPossessions",
 *             "policy.submission.furtherDetails.pedalCycles"
 *         ],
 *         // If the data from above should be flatMapped.
 *         "flatten": true
 *         // Format each item using the following string template.
 *         "template": "${item} ${currency}${value}",
 *     }
 * }
 */
export function initialiseClaimSchemas(context, schema, uiSchema, currency, language) {
    const format = currency && Intl.NumberFormat(language, {
        style: 'currency',
        currency
    });
    let referenceKeys = Object.keys(uiSchema)
            .filter(it => it.startsWith(REFERENCE_KEY));
    referenceKeys.forEach(key => {
        const {
            path,
            flatten,
            itemPath,
            template
        } = uiSchema[key];
        // Path can either be an array, or a single value.
        let value = Array.isArray(path) ? path.map(it => get(context, it)) : get(context, path);
        // If we want to 'flatted' the resulting arrays of arrays into just an array.
        if (flatten) value = value.flatMap(it => Array.isArray(it) ? it : [it]);
        // Get a specific value from within the items if required.
        if (itemPath) value = value.map(it => itemPath.split(',').map(str => get(it, str)).join(", "));
        // Format the values using a specified template:
        if (template) value = value.map(it => {
            let result = template.replace(/\${([a-zA-Z0-9]+)}/g,
                    (_, arg) => get(it, arg));
            if (format) result = result.replace(/\${ccy:([a-zA-Z0-9]+)}/g,
                    (_, arg) => format.format(get(it, arg))
                            .replace(/.00$/, ''));
            return result;
        });
        // Remove the prefix from the name of the prop.
        const target = key.substring(REFERENCE_KEY.length);
        // Finally update one of schema or uiSchema with the resulting value.
        set({
            schema,
            uiSchema
        }, target, value);
    });

    // Recurse through the schema.
    Object.keys(uiSchema)
            .forEach(key => {
                let childUiSchema = uiSchema[key];
                if (typeof childUiSchema === 'object' && !Array.isArray(childUiSchema)) {
                    // The JSON schema has an intermediate 'properties' object, uiSchema does not.
                    const schemaParentObject = schema.properties ? schema.properties : schema;
                    let childSchema = schemaParentObject[key];
                    const {
                        updatedSchema,
                        updatedUiSchema
                    } = initialiseClaimSchemas(context, childSchema || {}, uiSchema[key], currency, language);
                    if (updatedUiSchema === 'delete') delete uiSchema[key];
                    else if (Object.keys(updatedUiSchema).length > 0) uiSchema[key] = updatedUiSchema;
                    if (updatedSchema === 'delete') delete schemaParentObject[key];
                    else if (Object.keys(updatedSchema).length > 0) schemaParentObject[key] = updatedSchema;
                }
            });

    // If we're an 'enum' and the result is that there are no ooptions available then we remove the property altogether.
    if (schema.enum && schema.enum.length === 0) {
        return {
            updatedSchema: 'delete',
            updatedUiSchema: 'delete'
        };
    }

    // If we're an 'array' and the result is that there are no 'items' then we remove the property.
    if (schema.type === 'array' && !schema.items) {
        return {
            updatedSchema: 'delete',
            updatedUiSchema: 'delete'
        };
    }
    return {
        updatedSchema: schema,
        updatedUiSchema: uiSchema
    };
}
