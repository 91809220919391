import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Form from 'react-jsonschema-form';
import './Login.css';
import {Button, Error} from '../widgets';
import {trimEmailValue} from '../../utilities/FormatUtils';

const Schema = (emailAddressText, passwordText) => ({
    properties: {
        email: {
            type: 'string',
            title: emailAddressText,
            format: 'email'
        },
        password: {
            type: 'string',
            title: passwordText,
            format: 'password'
        }
    }
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = {formData: {email: ''}};
    }

    onChange = ({formData}) => {
        return this.setState({
            formData
        });
    };

    async onSubmit({formData}) {
        try {
            this.setState({pending: true});
            const {
                email,
                password
            } = formData;
            const trimmedEmail = trimEmailValue(email);
            const t = this.translatorOrDefault();
            // If the email does not exist in the system, we can go ahead and create it. Or if the password has been entered then we can attempt to auth with that.
            if (email && password) {
                try {
                    await this.props.login(trimmedEmail, password);
                } catch (e) {
                    this.setState({error: t('invalidPassword')});
                }
            }
        } finally {
            this.setState({pending: false});
        }
    }

    translatorOrDefault() {
        return this.props.t || ((it, {defaultValue}) => defaultValue || it);
    }

    render() {
        const {
            history,
            logo,
            title,
            productPath
        } = this.props;
        const display = this.display(title);
        const newPath = productPath || '';
        return (
            <div className="section screen no-border">
                <div className="login">
                    {logo && <img className="logo" alt={title} src={logo} onClick={() => history.push(`${newPath}/`)}/>}
                    {display}
                    <br/>
                    <div className="field">
                        {this.loginForm(newPath)}
                    </div>
                </div>
            </div>
        );
    }

    display(title) {
        const t = this.translatorOrDefault();
        const loginTitle = t('loginTitle', {defaultValue: `Welcome to ${title}`, productName: title});
        const loginDescription = t('loginDescription', {defaultValue: 'Please enter your email address to begin.'});
        if (this.props.lng != null && this.props.lng.includes('ja')) {
            return (<><br/><h3>{loginDescription}</h3></>);
        }
        return (<><h3>{loginTitle}</h3><p>{loginDescription}</p></>);
    }

    loginForm(newPath) {
        const t = this.translatorOrDefault();
        const {
            formData,
            pending
        } = this.state;
        const error = this.props.error || this.state.error;
        const uiSchema = {password: {'ui:widget': 'password'}};
        const emailAddress = t('emailAddress', {defaultValue: 'Email Address'});
        const password = t('password', {defaultValue: 'Password'});
        const schema = Schema(emailAddress, password);
        const clickToResetPassword = t('clickToResetPassword', {defaultValue: 'Click here to reset your password.'});
        const forgotPassword = t('forgottenYourPassword', {defaultValue: 'Forgotten your password?'});
        const clickHereToGetQuote = t('clickHereToGetQuote', {defaultValue: 'Click here to get a quote as a new user'});
        const dontHaveAccount = t('dontHaveAccount', {defaultValue: 'Don\'t have an account?'});
        const continueText = t('continue', {defaultValue: 'Continue'});
        return (
            <Form key="schema" formData={formData} noValidate omitExtraData liveOmit schema={schema} uiSchema={uiSchema} onChange={this.onChange}
                onSubmit={this.onSubmit}>
                <div>
                    {error && <Error>{error}</Error>}
                    <div className="text-right">
                        <Button pending={pending} className="btn btn-primary" type="submit">{continueText}</Button>
                    </div>
                    <div className="field text-right" style={{marginTop: 10}}>
                        {forgotPassword} <Link to={{
                            pathname: `${newPath}/reset`,
                            state: {email: formData.email}
                        }}>{clickToResetPassword}</Link>
                    </div>
                    <div className="field text-right">
                        {dontHaveAccount} <Link to={{
                            pathname: `${newPath}/`
                        }}>{clickHereToGetQuote}</Link>
                    </div>
                </div>
            </Form>
        );
    }
}

Login.defaultProps = {
    login: () => {
    }
};

Login.contextTypes = {
    router: PropTypes.object
};

export default Login;
