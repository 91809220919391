import {useDefaultTranslation} from "../common/Translation";
import {Link, useParams, useRouteMatch} from "react-router-dom";
import {convertToNewPath} from "../base";
import {useContext} from "react";
import {AuthContext} from "../common/context";

export const MessageScreen = ({message}) => {
    const {t} = useDefaultTranslation();
    const {path} = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);
    const {
        auth
    } = useContext(AuthContext);
    return (
        <div className="menu">
                <div className="offset-md-2 col-md-8">
                    <div className="row">
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <p>{t(message, {email: auth.username})}</p>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 20}}>
                        <div className="col-xs-12 offset-sm-2 col-sm-8">
                            <Link data-menukey="home" role="button" className="btn btn-primary gap float-right"
                                  to={`${newPath}/`}>{t('home')}</Link>
                        </div>
                    </div>
                </div>
            </div>
    );
};