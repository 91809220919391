import React from 'react';
import {Screen} from '../shared/Screen';
import {AuthContext} from '../common/context';
import {MessageScreen} from "./MessageScreen";

export const AmendEmailRequest = () => (
    <Screen>
            <AuthContext.Consumer>{() => (
                <MessageScreen message={'requestConfirmedEmail'} />
            )}</AuthContext.Consumer>
        </Screen>
);
