import React, {useContext, useEffect, useState} from 'react';
import {unflatten} from '@blocksure/blocksure-core/dist/src/utilities/Flatten';
import {AuthContext, CurrentStepContext, PolicyContext, PolicyholderContext, ProductContext, SubmissionContext} from '../common/context';
import {get, merge, mergeWith, set} from 'lodash';
import {getDefaultFormState} from 'react-jsonschema-form/lib/utils';

function extractSchemaData(data, stepSchema) {
    Object.entries(data)
            .forEach(([key, it]) => {
                if (!stepSchema.properties) return;
                if (!stepSchema.properties[key]) delete data[key];
                // These are stored as strings in the back end.
                if (data[key] === 'true') data[key] = true;
                if (data[key] === 'false') data[key] = false;
                else if (typeof data[key] === 'object') data[key] = extractSchemaData({...data[key]}, stepSchema.properties[key]);
            });
    return data;
}

export const SubmissionProvider = ({
    children,
    type
}) => {
    const {policy} = useContext(PolicyContext);
    const {policyholder} = useContext(PolicyholderContext);
    const {
        auth,
        surelyncProps
    } = useContext(AuthContext);
    const {
        product,
        submissionSchema,
        submissionUiSchema
    } = useContext(ProductContext);
    const {
        currentStep,
        stepNames,
        shouldRegister,
        stepSchema
    } = useContext(CurrentStepContext);
    const firstSchemaStep = stepNames.filter(it => it !== 'amend')[0];
    // Used to store the current submission in session storage.
    const storageKey = `com.blocksure.${type === 'claim' ? policy.id : product.id}.${type}`;

    const [submission, setSubmission] = useState(null);
    const [attachments, setAttachments] = useState({});

    // Update the whole submission object.
    const updateSubmission = (updated, init) => {
        const primaryEmailAddress = surelyncProps ? surelyncProps.policyholder.primaryEmailAddress : auth.username;
        const needAutoPopulate = auth.username !== 'anonymous' && !updated[firstSchemaStep]?.primaryEmailAddress;
        if (type === 'quote' && needAutoPopulate) updated = merge({}, updated, {[firstSchemaStep]: {primaryEmailAddress}});
        // Undefined means not yet loaded so only proceed if we're loading it (init = true) or the existing value is not empty.
        if (init || submission !== undefined) {
            sessionStorage.setItem(storageKey, JSON.stringify(updated));
            setSubmission({...updated});
        }
    };

    const policySubmission = () => submission && submission.meta ? {...policy.submission, meta: submission.meta} : policy.submission;

    // Load the submission.
    useEffect(() => {
        // If we're in the quote flow and there is a quote, we use the existing submission.
        let submission = policy && type === 'quote' ?
                policySubmission() :
                JSON.parse(sessionStorage.getItem(storageKey) || 'null');
        let primaryEmailAddress;
        let primaryEmailAddressKey;
        if(submission) {
            for (let key of Object.keys(submission)) {
                if(submission[key].primaryEmailAddress != null) {
                    primaryEmailAddress = submission[key].primaryEmailAddress;
                    primaryEmailAddressKey = key;
                } 
            }
        }
        // Email could be Amended - let's update it
        if (primaryEmailAddressKey &&
            submission[primaryEmailAddressKey] != null &&
            primaryEmailAddress &&
            primaryEmailAddress !== policyholder?.primaryEmailAddress
        ) {
            submission[primaryEmailAddressKey].primaryEmailAddress = policyholder?.primaryEmailAddress;
        }
        // Set as null if falsey so that we know we tried to load a submission.
        updateSubmission(submission || getDefaultFormState(submissionSchema, undefined, submissionSchema.definitions), true);
        // eslint-disable-next-line
    }, [policyholder && policyholder.id, policy && policy.id]);

    const {
        quoteOptions,
        constants,
        assumptions
    } = get(submissionUiSchema, 'quote[\'ui:options\']') || {};
    const steps = get(submissionUiSchema, 'ui:order');

    const initializedSubmission = submission || getDefaultFormState(submissionSchema, undefined, submissionSchema.definitions);

    // This is the current submission with the constants and assumptions applied to it.
    const submissionWithAssumptions = getAssumptionsObjectInternal(currentStep, steps, initializedSubmission, quoteOptions, constants, assumptions);

    // This is the submission values we've had input so fare for the current step only.
    const stepSubmission = initializedSubmission[currentStep];

    // Updates the submission object with values for the currently active step.
    const updateStepSubmission = updatedStepSubmission => {
        let merged = mergeWith({}, initializedSubmission, {[currentStep]: updatedStepSubmission}, customizer);
        updateSubmission(merged);
    };

    const updateAttachments = (add = {}, remove = []) => {
        const updated = {...attachments, ...add};
        remove.forEach(it => delete updated[it]);
        setAttachments(updated);
    };

    // Function to customize values when updating submission.
    function customizer(objValue, srcValue) {
        // Replacing undefined values with null when emptying fields
        if (srcValue === undefined) {
            return null;
        }
        return srcValue;
    }

    useEffect(() => {
        // If we are on a step that updates the policyholder, we pre-populate it with data from the policyholder.
        if (submission && shouldRegister && policyholder) {
            const schemaData = extractSchemaData(unflatten(policyholder.shared), stepSchema);
            updateStepSubmission(merge({}, schemaData, submission[currentStep]));
        }
        // eslint-disable-next-line
    }, [policyholder && policyholder.id, policy && policy.id, currentStep]);

    // Returns a submission with one of th X sets of assumptions applied.
    const getAssumptionsObject = (i, includeAssumptions = true) => getAssumptionsObjectInternal(currentStep, steps, submission, quoteOptions, constants, includeAssumptions && assumptions, i);
    return (
            <SubmissionContext.Provider value={{
                submission,
                submissionWithAssumptions,
                updateSubmission,
                stepSubmission,
                updateStepSubmission,
                getAssumptionsObject,
                attachments,
                updateAttachments
            }}>
                {children}
            </SubmissionContext.Provider>
    );
};

export function getAssumptionsObjectInternal(currentStep, steps, submission, quoteOptions, constants, assumptions, i) {
    const submissionAssumptions = {};

    // If we're pass an index, then we're getting the assumptions for one of the defined quote options.
    if (i !== undefined) {
        (quoteOptions || []).forEach(({
            path,
            values
        }) => {
            set(submissionAssumptions, path, values[i]);
        });
    }

    // Apply any constants - these have no associated form field.
    Object.entries(constants || {})
            .forEach(([name, item]) => {
                set(submissionAssumptions, name, item);
            });

    // Apply any assumptions from the schema (these are things that will be asked explicitly in subsequent steps).
    Object.entries(assumptions || {})
            .forEach(([name, item]) => {
                set(submissionAssumptions, name, item);
            });

    // We remove values from steps that are beyond the current one.
    const result = {};
    let seenCurrentStep = false;
    steps.forEach(step => {
        if (!seenCurrentStep) {
            result[step] = merge({}, submission[step]);
            seenCurrentStep = seenCurrentStep || step === currentStep;
        }
    });
    return merge({}, result, submissionAssumptions);
}
