import React, {useContext, useState} from 'react';
import {Error} from '@blocksure/blocksure-core/dist/src/components/widgets';
import {
    AuthContext,
    CurrentStepContext,
    MetadataContext,
    PendingContext,
    PolicyContext,
    PolicyholderContext,
    ProductContext,
    SubmissionContext
} from '../common/context';
import QuickQuote from './QuickQuote';
import Schema from '../schema/Schema';
import {Link, useParams, useRouteMatch} from 'react-router-dom';
import {validate} from '../schema/Validation';
import {createQuote} from './quoteActions';
import {useDefaultTranslation} from '../common/Translation';
import {addAutoFocus} from '../schema/SchemaUtils';
import {merge} from 'lodash';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import {convertToNewPath} from "../base";

export const QuoteStep = ({
    children,
    amend
}) => {
    const {
        t,
        i18n
    } = useDefaultTranslation();
    const [error, setError] = useState(false);
    const {
        pending,
        pendingAction
    } = useContext(PendingContext);
    const {
        auth,
        login,
        silentChangeAuth
    } = useContext(AuthContext);
    const {
        product,
        submissionUiSchema
    } = useContext(ProductContext);
    const {
        policyholder,
        updatePolicyholder
    } = useContext(PolicyholderContext);
    const {
        quote,
        updatePolicy
    } = useContext(PolicyContext);
    const {
        currentStep,
        stepSchema,
        stepUiSchema,
        nextStep,
        shouldQuote,
        shouldRegister
    } = useContext(CurrentStepContext);
    const {
        submission,
        updateStepSubmission,
        stepSubmission,
        attachments
    } = useContext(SubmissionContext);
    const {metadata} = useContext(MetadataContext);
    const [emailExists, setEmailExists] = useState(false);
    const { path } = useRouteMatch();
    const {id} = useParams();
    const newPath = convertToNewPath(path, id);

    const tryAuth = async (email) => {
        // If we have entered a username than we can try and register a new policyholder.
        if (email) {
            try {
                await login(email);
                setEmailExists(false);
                return true;
            } catch (e) {
                setEmailExists(true);
                return false;
            }
        }
        return false;
    };

    const onSubmit = ({formData = {}}) => {
        pendingAction(async () => {
            setError(null);
            try {
                // Check if we are already authenticated or not.
                if ((!auth || auth.username === 'anonymous') && !await tryAuth(formData.primaryEmailAddress)) return;
                const primaryPhoneNumber = formData?.primaryPhoneNumber?.replace(/ /g, '');
                if (primaryPhoneNumber) {
                    formData.primaryPhoneNumber = primaryPhoneNumber;
                }
                if (shouldRegister) {
                    collectPolicyholderData(submissionUiSchema, formData, submission);

                    await updatePolicyholder({...formData}, stepUiSchema, product.id);

                    // Allow email address field to be changed via Quote wizard
                    if (auth.username !== 'anonymous' && formData.primaryEmailAddress && formData.primaryEmailAddress.toLowerCase() !== auth.username) {
                        await silentChangeAuth();
                    }

                }
                // We don't 'quote' on an amend or if the product is set up to skip the payment - the data is generated realtime on the pay screen.
                if (!amend && shouldQuote) {
                    await createQuote(policyholder, quote, t, product, submission, metadata, i18n, attachments, auth, updatePolicy);
                }
                if (nextStep) nextStep();
            } catch (e) {
                console.error('Error submitting form', e);
                setError(generateErrorMessage(e));
            }
        });
    };

    const onChange = async ({formData}) => {
        if (formData && stepSubmission && formData.primaryEmailAddress !== stepSubmission.primaryEmailAddress) {
            setEmailExists(false);
         }
        if (Object.keys(formData).length === 0) return;
        updateStepSubmission(formData);
    };

    const fields = stepUiSchema['ui:order'] || Object.keys(stepSchema.properties);
    const uiSchemaWithAutofocus = addAutoFocus(fields, stepUiSchema);
    return (
            <div className="section rjsf offset-md-1 col-md-10 offset-lg-2 col-lg-8 gap">
                {!amend && stepUiSchema['ui:quickQuote'] !== false && <QuickQuote/>}
                {error && <Error>{error}</Error>}

                {/* This key property is essential. Otherwise, when we transition to the next step, the submission for the current step gets reset to the blank values of the next step.*/}
                <Schema amend={amend} key={currentStep} schema={stepSchema} formData={stepSubmission} uiSchema={uiSchemaWithAutofocus} onSubmit={onSubmit}
                        pending={pending} onChange={onChange} validate={validate(stepUiSchema, stepSchema, t)}>
                    {emailExists && <Link to={`${newPath}/login`}><h4 className="text-danger text-center">{t('accountInlineExists')}</h4></Link>}
                    {!emailExists && children}
                </Schema>
            </div>
    );
};

// If fm:collectPolicyholderData is present, we collect policyholder information from submission
export function collectPolicyholderData(submissionUiSchema, formData, submission) {
    Object.entries(submissionUiSchema)
            .forEach(([key, value]) => {
                if (value['fm:collectPolicyholderData'])
                    merge(formData, submission[key]);
            });
}
