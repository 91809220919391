import {base,  SureAppName} from '../base';
import {PaymentApiClient, PolicyApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import * as FormatUtils from '@blocksure/blocksure-core/dist/src/utilities/FormatUtils';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';

const paymentApiClient = new PaymentApiClient(SureAppName);
const policyApiClient = new PolicyApiClient(SureAppName);

export const pay = async (transactionId, paymentSourceId) => {
    try {
        const {
            attempts,
            sequenceId
        } = await policyApiClient.requestPayment(paymentSourceId, transactionId);
        const lastAttempt = attempts[attempts.length - 1];
        if (lastAttempt && lastAttempt.status.toUpperCase() !== 'SUCCESS') throw new Error('Payment failed');
        return sequenceId;
    } catch (e) {
        console.error(e);
        throw new Error(generateErrorMessage(e, 'Unable to process payment.'));
    }
};

export const mtaPolicy = async (quote, product, submission, amendDate, paymentSourceId, meta, language, attachments) => {
    try {
        if (amendDate == null) throw Error('Amend date not found.');
        const effectiveDate = FormatUtils.parseDate(amendDate)
                .toISO();
        const combinedMeta = {
            ...meta,
            appPath: quote.meta['appPath'] || base,
            locale: language,
            timesInteracted: 0
        };
        const duration = deriveDuration(quote.expiryDate, quote.inceptionDate);
        const [attachmentArray, attachmentObjects] = prepareAttachments(attachments);
        const {
            transactions,
            sequenceId
        } = await policyApiClient.mtaPolicy(
                paymentSourceId,
                quote.sequenceId,
                combinedMeta,
                submission,
                duration,
                effectiveDate,
                attachmentObjects,
                attachmentArray,
                quote.payFrequency
        );
        const correctionTransaction = transactions.find(transaction => transaction.transactionType === 'Correction');
        if (correctionTransaction) {
            const lastAttempt = correctionTransaction.attempts[correctionTransaction.attempts.length - 1];
            if (lastAttempt && lastAttempt.status.toUpperCase() !== 'SUCCESS') throw new Error('Payment failed');
        }
        return sequenceId;
    } catch (e) {
        console.error(e);
        throw new Error(generateErrorMessage(e, 'Unable to MTA policy.'));
    }
};

export const previewMta = async (policy, submission, amendDate) => {
    try {
        if (policy === null) throw Error('Policy was not found.');
        if (amendDate == null) throw Error('Amend date not found.');
        const effectiveDate = FormatUtils.parseDate(amendDate)
                .toISO();
        const duration = deriveDuration(policy.expiryDate, policy.inceptionDate);
        return policyApiClient.mtaPolicyPreview(policy.sequenceId, policy.meta, submission, duration, effectiveDate, policy.payFrequency);
    } catch (e) {
        console.error(e);
        throw new Error(generateErrorMessage(e,'Unable to process amendment.'));
    }
};

export const getPaymentSources = async (paymentGateway, policyholderId) => {
    try {
        return paymentApiClient.getPaymentSources(paymentGateway, policyholderId);
    } catch (e) {
        console.error(e);
        throw new Error(generateErrorMessage(e,'Unable to get payment sources.'));
    }
};

export const regsiterPaymentSource = async (stripe, paymentGateway, policyholderId) => {
    // This line needs to run before we change to the 'paying' state or the controls are unloaded and stripe gets sad.
    const cardToken = await stripe.createToken({
        name: 'Blocksure',
        currency: 'GBP'
    });
    if (!cardToken || cardToken.error) throw new Error('Failed to register card', cardToken && cardToken.error);
    const {id} = await paymentApiClient.registerPaymentSource(paymentGateway, policyholderId, cardToken.token.id);
    return id;
};

function deriveDuration(from, to) {
    let duration = FormatUtils.parseDate(from)
            .diff(FormatUtils.parseDate(to, 'hours'))
            .toISO();
    if (duration === 'PT8760H') duration = 'P1Y';
    if (duration === 'PT720H') duration = 'P1M';
    if (duration === 'PT24H') duration = 'P1D';
    return duration;
}

function prepareAttachments(attachments) {
    const attachmentArray = Object.entries(attachments)
            .map(([name, file]) => ({
                name,
                file
            }));
    const attachmentObjects = Object.entries(attachments)
            .map(([name, file]) => ({
                name: name,
                modifiedDate: file.lastModified / 1000,
                description: file.name,
                mediaType: file.type
            }));

    return [attachmentArray, attachmentObjects];
}
