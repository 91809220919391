import React, { useContext, useEffect, useState } from 'react';
import { PolicyholderApiClient } from '@blocksure/blocksure-core/dist/src/services/api-clients';
import { flatten } from '@blocksure/blocksure-core/dist/src/utilities/Flatten';
import { generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import {AuthContext, PolicyholderContext} from '../common/context';
import {getBase,  SureAppName} from '../base';
import {prepareSharedInfoFromSchema, resolveCountry, splitLocal} from '../shared/util';
import {useDefaultTranslation} from '../common/Translation';
import ProductApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/ProductApiClient";

const policyholderApiClient = new PolicyholderApiClient(SureAppName);
const productApiClient = new ProductApiClient(SureAppName)

/**
 * Provides global state for the policyholder object for the currently auth'ed user.
 */
export const PolicyholderProvider = ({ children }) => {
    const {
        auth = {},
        surelyncProps
    } = useContext(AuthContext) || {};
    const { i18n } = useDefaultTranslation();
    const [policyholder, setPolicyholder] = useState(null);
    const [universalSchema, setUniversalSchema] = useState(null);
    const [country, setCountry] = useState(null);

    const reloadPolicyholder = async () => {
        if (policyholder && auth.username === policyholder.primaryEmailAddress) return;
        if (policyholder && auth.username !== policyholder.primaryEmailAddress) setPolicyholder(null);

        if (surelyncProps) {
            setPolicyholder(surelyncProps.policyholder);
        } else {
            try {
                const policyholder = await policyholderApiClient.getPolicyholder(auth.username);
                setPolicyholder(policyholder);
            } catch (e) {}
        }
    };

    const loadSchema = async (uiSchema) => {
        if (uiSchema) {
            const country = resolveCountry(uiSchema);
            const schema = await policyholderApiClient.getSchema(country, policyholder?.type);
            setUniversalSchema(schema);
            setCountry(country);
            return schema;
        }
    };

    useEffect(() => {
        try {
            reloadPolicyholder();
        } catch (e) {
            console.error(e);
            throw new Error(generateErrorMessage(e, 'Unable to reload'));
        }
        // eslint-disable-next-line
    }, [auth.username]);

    const updatePolicyholder = async (formData, uiSchema, productId, updatedEmail) => {
        try {
            const {hiddenAttachments, countryLockedPhone} = await productApiClient.getProduct(productId)
            const policyholderSchema = universalSchema || await loadSchema(uiSchema) || await loadSchema(hiddenAttachments["submissionSchema.json"]);
            const policyholderCountry = country || resolveCountry(uiSchema) || resolveCountry(hiddenAttachments["submissionSchema.json"]);

            // console.info('universalSchema', policyholderSchema);
            const ph = surelyncProps ? surelyncProps.policyholder : (policyholder || {});
            const emailAddress = updatedEmail ? updatedEmail : ph.primaryEmailAddress;
            const {
                primaryEmailAddress = emailAddress,
                primaryPhoneNumber = ph.primaryPhoneNumber,
                ...allData
            } = { ...formData };
            const sharedInfo = prepareSharedInfoFromSchema(formData, policyholderSchema, ph);
            const submissionSchema = await productApiClient.getAttachment(productId, hiddenAttachments.find(({name}) => name === "submissionSchema.json").location)
            let isMultiProduct = false;
            try {
                const products = submissionSchema.properties.quote["ui:options"].products
                if(products.length > 1) {
                    isMultiProduct = true
                    productId = Object.values(products)
                }
            } catch (exception) {}
            const { local } = splitLocal(allData, uiSchema);
            const updatedPolicyholder = {
                primaryEmailAddress,
                primaryPhoneNumber,
                shared: {
                    ...ph.shared, ...sharedInfo,
                    appPath: getBase(),
                    locale: i18n.language,
                    schemaCountry: policyholderCountry
                },
                local: flatten(local)
            };

            updatedPolicyholder.shared.contact.primaryEmailAddress = primaryEmailAddress;
            preparePolicyholder(updatedPolicyholder);
            const updated = ph.id ? await policyholderApiClient.updatePolicyholder(ph.id, updatedPolicyholder, countryLockedPhone)
                : await policyholderApiClient.createPolicyholder(updatedPolicyholder, isMultiProduct ? productId : [productId], countryLockedPhone);
            // We used to call 'reloadPolicyholder' here, but the system seems to think the policyholder doesn't exist immediately after we create it.
            setPolicyholder(updated);
        } catch (e) {
            console.error(e);
            throw new Error(generateErrorMessage(e, 'Unable to register, please try again later.'));
        }
    };

    return (
        <PolicyholderContext.Provider value={{
            policyholder,
            updatePolicyholder
        }}>
            {children}
        </PolicyholderContext.Provider>
    );
};

const preparePolicyholder = policyholder => {
    if (!policyholder.primaryPhoneNumber) policyholder.primaryPhoneNumber = '';
};
