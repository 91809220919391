import React from 'react';
import {get} from 'lodash';
import {InProductCurrency} from '../widgets/InProductCurrency';
import {useDefaultTranslation} from './Translation';
import {ImageArray} from './ImageArray';

export const SummaryPanel = ({
    submission,
    items,
    product
}) => {
    const {t} = useDefaultTranslation();

    return (
            <div className="gap">
                {(items || []).map(({
                    title,
                    path,
                    value,
                    format,
                    ...config
                }) => {
                    const titleTranslated = t(`payment.paySummaryItems.${path}.tx-title`, {defaultValue: title});
                    const valueTranslated = t(`payment.paySummaryItems.${path}.tx-value`, {defaultValue: value});
                    return (
                            <div key={path} className="row" id={path}>
                                <div className="col-8">
                                    <p className={'key'}>{titleTranslated}</p>
                                </div>

                                {valueTranslated ? (
                                        <div className="col-4">
                                            <p className="row-item text-right">{valueTranslated}</p>
                                        </div>
                                ) : renderValue(t, submission, product, path, title, format, config)}
                            </div>
                    );
                })}
            </div>
    );
};

function renderValue(t, submission, product, name, title, format, config) {
    const value = get(submission, name);
    // For small, inline values.
    const Short = ({
        children,
        push = 2
    }) => (
            <div className={`col-4`}>
                <p className="row-item text-right">{children || '-'}</p>
            </div>
    );
    const Long = ({
        children,
        className,
        fullWidth = false
    }) => (
            <div className={'col-12 text-right' + (className ? ` ${className}` : '')}>
                {children || '-'}
            </div>
    );
    if (format === 'currency') return <Short><InProductCurrency product={product} amount={value} trim/></Short>;
    if (format === 'boolean') return <Short>{value ? t('yes') : t('no')}</Short>;
    if (format === 'integer') return <Short>{value}</Short>;
    if (format === 'multiline') return value ? <Long>{value}</Long> : <Short>-</Short>;

    if (Array.isArray(value)) {
        let preparedValue = value;
        if (config.itemPath) preparedValue = preparedValue.map(it => get(it, config.itemPath));
        if (config.separate) preparedValue = preparedValue.map(it => it.split(', ')[config.separate])

        if (format === 'array') {
            return preparedValue.length === 0 ? <Short>-</Short> : preparedValue.map((it, i) => i === 0 ? <Short key={i}>{it}</Short> :
                    <Long key={i}>{it}</Long>);
        }

        if (format === 'image-array') {
            return preparedValue.length === 0 ? <Short>-</Short> : <Long className="gap" fullWidth><ImageArray images={preparedValue}/></Long>;
        }

        if (format === 'array-sum') {
            const result = preparedValue.length === 0 ? 0 : preparedValue.reduce((a, b) => a + b);
            return <Short>{config.itemFormat === 'currency' ? <InProductCurrency amount={result} trim/> : `${result}`}</Short>;
        }
    }
    const productCurrency = (product.payment && product.payment.currency) || (product && product.currency);
    const denominator = productCurrency === 'JPY' ? 10000 : 1;
    const updatedValue = isNaN(value) ? value : value / denominator;
    return <Short>{t('amountInThousands', {amount: updatedValue})}</Short>;
}
