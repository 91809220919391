import React, {useContext, useState} from 'react';
import DatePicker from 'react-datepicker';
import {getPrice, SinglePrice} from '../quote/QuoteArray';
import {Instalments} from '../shared/Instalments';
import {Button} from '@blocksure/blocksure-core/dist/src/components/widgets';
import {
    AuthContext,
    MetadataContext,
    PendingContext,
    PolicyContext,
    ProductContext,
    SubmissionContext
} from '../common/context';
import {refreshQuote} from '../quote/quoteActions';
import {useDefaultTranslation} from '../common/Translation';
import * as FormatUtils from '@blocksure/blocksure-core/dist/src/utilities/FormatUtils';

export const YourQuote = ({
    product,
    quote,
    amend,
    validPayFrequencies
}) => {
    const {t} = useDefaultTranslation();
    const dateFormat = t("dateFormat") || 'DD'
    return (
            <div>
                {!amend && <div className=" text-center gap">
                    {t('yourPolicyWillStartOn', {startDate: FormatUtils.renderDate(quote.inceptionDate, dateFormat)})}
                    <AdjustStartDate/>
                </div>}
                {amend && <div className=" text-center gap">
                    {t('theseChangesWillBeEffective', {
                        effectiveFromDate: FormatUtils.renderDate(quote.effectiveFromDate, dateFormat),
                        effectiveToDate: FormatUtils.renderDate(quote.effectiveToDate, dateFormat)
                    })}
                </div>}

                <div className="col-md-12 text-center">
                    <SinglePrice {...getPrice(quote)} product={product}/>
                </div>

                <div className="col-md-12 text-center gap">
                    <div className="message-body">
                        <Instalments transactions={quote && quote.transactions}/>
                        {!amend && <AdjustPayFrequency {...{validPayFrequencies}} />}
                    </div>
                </div>
            </div>
    );
};

const AdjustPayFrequency = ({validPayFrequencies}) => {
    const {
        t,
        i18n
    } = useDefaultTranslation();
    const [editFreq, setEditFreq] = useState(false);
    const {
        pending,
        pendingAction
    } = useContext(PendingContext);
    const {
        submission,
        attachments
    } = useContext(SubmissionContext);
    const {
        quote,
        updatePolicy
    } = useContext(PolicyContext);
    const {metadata} = useContext(MetadataContext);
    const {auth} = useContext(AuthContext);
    const {product} = useContext(ProductContext);

    const adjustPayFrequency = async payFrequency => pendingAction(async () => {
        const policy = await refreshQuote(t, quote, quote.policyholderId, product, submission, payFrequency, null, metadata, i18n.language, attachments, auth);
        await updatePolicy(policy);
    });

    return (
            <span>
            {validPayFrequencies.length > 1 &&
            <button className="btn-link text-info" disabled={pending} onClick={() => setEditFreq(!editFreq)}>({t('change')
                    .toLowerCase()})</button>}
                <div className={`text-center gap ${editFreq ? '' : 'hidden'}`}>
                    <div className="btn-group btn-group-md" role="group" aria-label="...">
                        {validPayFrequencies.map(it => {
                                    const text = t(`pay${it}`) || it;
                                    return <Button key={it} disabled={pending} type="button"
                                                   className={`btn btn-${quote.payFrequency === it ? 'primary' : 'default'} ${pending ? 'disabled' : ''}`}
                                                   onClick={() => adjustPayFrequency(it)}>{text}</Button>;
                                }
                        )}
                    </div>
            </div>
        </span>
    );
};

const AdjustStartDate = () => {
    const {
        t,
        i18n
    } = useDefaultTranslation();
    const [editStart, setEditStart] = useState(false);
    const {
        pending,
        pendingAction
    } = useContext(PendingContext);
    const {
        quote,
        updatePolicy
    } = useContext(PolicyContext);
    const {
        submission,
        attachments
    } = useContext(SubmissionContext);
    const quoteStartDate = (quote.inceptionDate && new Date(quote.inceptionDate)) || new Date();
    const {metadata} = useContext(MetadataContext);
    const {auth} = useContext(AuthContext);
    const {product} = useContext(ProductContext);

    const adjustStartDate = async startDate => pendingAction(async () => {
        const policy = await refreshQuote(t, quote, quote.policyholderId, product, submission, null, startDate, metadata, i18n.language, attachments, auth);
        await updatePolicy(policy);
    });

    return (
            <span>
            <button className="btn-link text-info" disabled={pending} onClick={() => setEditStart(!editStart)}>({t('change')
                    .toLowerCase()})</button>
            <div className={`text-center gap ${editStart ? '' : 'hidden'}`}>
                <label htmlFor="startDate">{t('startDate')}</label> &nbsp;
                <DatePicker
                        id="startDate"
                        className="form-control"
                        disabled={pending}
                        dateFormat="dd/MM/yyyy"
                        selected={quoteStartDate}
                        onChange={adjustStartDate}
                        minDate={new Date()}
                />
            </div>
        </span>
    );
};
