import { ISettlementStatement } from "@surelync/common";

export const setDataSettlementStatement = (data: ISettlementStatement[]) => {
    return data.map((item) => ({
        reportingRef: item.reportingRef,
        transactionId: item.transactionId,
        paid: item.paid || null,
        available: item.available || null,
        status: item.status,
        currency: item.currency,
        grossAmount: item.grossAmount,
        paymentFee: item.paymentFee,
        netAmount: item.netAmount,
        paymentGateway: item.paymentGateway,
        id: item.id,
        availableValue: item.available,
    }));
};
