import React, {useContext, useState} from "react";
import {changeRequiredMessage, resetRequiredMessage} from "../SchemaUtils";
import {useDefaultTranslation} from "../../common/Translation";
import {ProductContext} from "../../common/context";

function LicenseWidget(props) {
    const {
        id,
        name,
        readonly,
        required,
        onBlur,
        onChange,
        onFocus,
        formData
    } = props;
    const {t} = useDefaultTranslation();
    const {
        product
    } = useContext(ProductContext);
    let [license, setLicense] = useState();
    const quoteData = JSON.parse(sessionStorage.getItem('com.blocksure.' + product.id + '.quote'))
    let prefix = generateLicensePrefix(quoteData);

    if (formData !== undefined && license === undefined) {
        if (formData.length > 3) {
            setLicense(formData.substring(formData.length - 3));
        } else {
            setLicense(formData);
        }
    }
    const onLicenseChange = (e) => {
        resetRequiredMessage(e);
        setLicense(e.target.value)
        onChange(prefix + e.target.value + 'XX');
    }

    return <div className="input-group input-group-lg row-cols-3">
            <div className="input-group-prepend" style={{display: 'inline-block'}}>
                <p className="input-group-text"
                   id="licenseFirstPart">
                    {prefix}
                </p>
            </div>
            <div className="input-group-lg" style={{display: 'inline-flex'}}>
                <input
                    className="form-control caps text-center"
                    disabled={readonly}
                    name={name}
                    id={id}
                    required={required}
                    value={license === undefined ? '' : license}
                    onBlur={onBlur && (event => onBlur(id, event.target.value))}
                    onChange={onLicenseChange}
                    maxLength={3}
                    minLength={3}
                    onFocus={onFocus && (event => onFocus(id, event.target.value))}
                    onInvalid={required ? (event => changeRequiredMessage(event, t)) : null}
                />
                </div>
            <div className="input-group-append" style={{display: 'inline-flex'}}>
                <span className="input-group-text" id="licenseFirstPart"
                      style={{paddingLeft: '10px'}}>
                    XX
                </span>
            </div>
        </div>
}

function generateLicensePrefix(formData) {
    return (lastName(formData) + decade(formData) + month(formData) + date(formData) + year(formData) + initials(formData)).toUpperCase();
}

function lastName(formData) {
    return `${formData.yourDetails.lastName}9999`.substr(0, 5);
}

function decade(formData) {
    return `${formData.yourDetails.dateOfBirth.split('-')[0]}`.substr(2, 1);
}

function month(formData) {
    let month = `0${formData.yourDetails.dateOfBirth.split('-')[1]}`.slice(-2);
    if (formData.yourDetails.gender === 'Female') {
        month = (+month + 50).toString();
    }
    return month;
}

function date(formData) {
    return `0${formData.yourDetails.dateOfBirth.split('-')[2]}`.slice(-2);
}

function year(formData) {
    return `${formData.yourDetails.dateOfBirth.split('-')[0]}`.substr(3, 1);
}

function initials(formData) {
    return `${(formData.yourDetails.firstName.substr(0, 1) + (formData.yourDetails.middleName ? formData.yourDetails.middleName.substr(0, 1) : '9'))}`;
}

export default LicenseWidget;