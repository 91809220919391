import {base,  SureAppName} from '../base';
import {PolicyApiClient} from '@blocksure/blocksure-core/dist/src/services/api-clients';
import * as PolicyUtils from '@blocksure/blocksure-core/dist/src/utilities/PolicyUtils';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';

const policyApiClient = new PolicyApiClient(SureAppName);


/**
 * Updates the current quote we have and logs/throws a human friendly error if something goes wrong.
 */
export const refreshQuote = async (t, quote, policyholderId, product, submission, newPayFrequency, newStartDate, metadata, language, attachments, auth) => {
    try {
        const {billingEntityId, dueDateOverride, otherSubmission} = prepareSubmission(submission);
        const [defaultTerm, previousQuoteId, payFrequency, effectiveFrom, combinedMeta, attachmentArray, attachmentObjects] = prepareParams(quote, product, newPayFrequency, newStartDate, metadata, language, attachments, billingEntityId, dueDateOverride);

        // We don't really 'quote' if the product is set up to skip the payment - we just call insurance-engine to simulate a quote without storing it on the ledger.
        const quotePreview = PolicyUtils.shouldSkipPayment(auth, product);

        return await policyApiClient.quoteWithAttachments(product.id, combinedMeta, otherSubmission, defaultTerm, effectiveFrom, policyholderId, payFrequency, previousQuoteId, attachmentObjects, attachmentArray, quotePreview);
    } catch (e) {
        console.error(e);
        throw new Error(generateErrorMessage(e, t('unableToLoadQuotes')));
    }
};

/**
 * Bind a policy without storing a quote on the ledger and logs/throws a human friendly error if something goes wrong.
 */
export const bind = async (t, quote, policyholderId, product, submission, newPayFrequency, newStartDate, metadata, language, attachments, paymentSource) => {
    try {
        const {billingEntityId, dueDateOverride, otherSubmission} = prepareSubmission(submission);
        const [defaultTerm, previousQuoteId, payFrequency, effectiveFrom, combinedMeta, attachmentArray, attachmentObjects] = prepareParams(quote, product, newPayFrequency, newStartDate, metadata, language, attachments, billingEntityId, dueDateOverride);

        // Bind a policy without storing a quote on the ledger
        const {sequenceId} = await policyApiClient.bind(product.id, combinedMeta, otherSubmission, defaultTerm, effectiveFrom, policyholderId, payFrequency, previousQuoteId, attachmentObjects, attachmentArray, paymentSource);
        return sequenceId;
    } catch (e) {
        console.error(e);
        throw new Error(generateErrorMessage(e, t('unableToBindPolicy')));
    }
};

function prepareParams(quote, product, newPayFrequency, newStartDate, meta, language, attachments, billingEntityId, dueDateOverride) {
    const {
        validPayFrequencies: [defaultPayFrequency],
        validPolicyTerms: [defaultTerm]
    } = product;
    const previousQuoteId = quote ? quote.quoteId : undefined;

    // Use new value if passed, or use values from existing quote.
    const payFrequency = newPayFrequency || (quote || {}).payFrequency || defaultPayFrequency;
    const effectiveFrom = newStartDate || (quote || {}).effectiveFromDate || new Date();
    const policyTerms = (quote || {}).meta !== undefined ? ('policyTerm' in quote.meta ? quote.meta['policyTerm'] : defaultTerm) : defaultTerm;

    const combinedMeta = {
        ...meta,
        timesInteracted: 0,
        appPath: base,
        locale: language
    };
    const attachmentArray = Object.entries(attachments)
            .map(([name, file]) => ({
                name,
                file
            }));
    const attachmentObjects = Object.entries(attachments)
            .map(([name, file]) => ({
                name: name,
                modifiedDate: file.lastModified / 1000,
                description: file.name,
                mediaType: file.type
            }));

    if (billingEntityId) combinedMeta.billingEntityId = billingEntityId;
    if (dueDateOverride) combinedMeta.dueDateOverride = dueDateOverride;

    return [policyTerms, previousQuoteId, payFrequency, effectiveFrom, combinedMeta, attachmentArray, attachmentObjects];
}

function prepareSubmission(submission) {
  const {meta, ...otherSubmission } = submission;
  const {billingEntityId, dueDateOverride} = meta || {};

  return {billingEntityId, dueDateOverride, otherSubmission};
}

export async function createQuote(policyholder, quote, t, product, submission, metadata, i18n, attachments, auth, updatePolicy) {
    const policyholderId = policyholder ? policyholder.id : quote ? quote.policyholderId : undefined;
    const policy = await refreshQuote(t, quote, policyholderId, product, submission, null, null, metadata, i18n.language, attachments, auth);
    const productCurrency = (product.payment && product.payment.currency) || (product && product.currency);

    window.gtag('event', 'add_to_cart', {
        value: policy.totalPayable.cumulative,
        currency: productCurrency,
        items: [{
            item_id: policy.sequenceId,
            item_name: `${policy.reportingRef} Quote`,
            price: policy.totalPayable.cumulative,
            quantity: 1
        }]
    });
    window.gtag('event', 'page_view');

    await updatePolicy(policy);
}