import React from 'react';
import { changeRequiredMessage, resetRequiredMessage } from '../SchemaUtils';
import { useDefaultTranslation } from '../../common/Translation';
import {Link, useParams, useRouteMatch} from "react-router-dom";
import {convertToNewPath} from "../../base";

function EmailWidget(props) {
    const {
        disabled,
        id,
        name,
        readonly,
        required,
        schema,
        value,
        onBlur,
        onChange,
        onFocus,
    } = props;
    const {t} = useDefaultTranslation();
    const { path } = useRouteMatch();
    const {idProd} = useParams();
    const newPath = convertToNewPath(path, idProd);

    const placeholder = schema['ui:placeholder'] || '';
    const alreadyHaveAnAccount = t('alreadyHaveAnAccount', {defaultValue: "If you already have an account,"});
    const clickHereToLogIn = t('clickHereToLogIn', {defaultValue: "Click here to log in."}).toLocaleLowerCase();

    return (
        <div>
            <input
                className="form-control" 
                disabled={disabled || readonly}
                id={id}
                name={name}
                type="email"
                placeholder={placeholder} 
                required={required}
                value={value}
                onBlur={onBlur && (event => onBlur(id, event.target.value))}
                onChange={event => {
                    resetRequiredMessage(event);
                    onChange(event.target.value);
                }}
                onFocus={onFocus && (event => onFocus(id, event.target.value))}
                onInvalid={required ? (event => changeRequiredMessage(event, t)) : null}
            />
            <div className="field text-right" style={{marginTop: 10}}>
                {alreadyHaveAnAccount} <Link to={{
                    pathname: `${newPath}/login`
                }}>{clickHereToLogIn}</Link>
            </div>
        </div>
    );
}

export default EmailWidget;
